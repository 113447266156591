#nuestros-clientes{
    padding: 50px 0px;
    @media only screen and (min-width: 768px) and (max-width: 1999px) {
      padding: 50px 0px;
    }
    @media only screen and (min-width: 1200px) {
      padding: 50px 0px;
    }
    img{
        width: 100%;
        max-width: 190px;
        margin: 0 auto;
    }
}