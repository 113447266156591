#objetivos {
  padding: 50px 0px;
  @media only screen and (min-width: 768px) and (max-width: 1999px) {
    padding: 50px 0px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 100px 0px;
  }
  h2{
      color: $blanco;
  }
  p{
      color: $blanco;
  }
  .line{
      border-color: $blanco;
  }
}
