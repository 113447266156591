body,html{ 
  box-sizing: inherit;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $bold;
}
p,
a,
li {
  font-family: $ligera;
  &:hover{
    text-decoration: none;
  }
}
.azul {
  color: $primary;
}
h2 {
  font-size: 2.5rem;
  @media only screen and (min-width: 1200px) {
    font-size: 60px;
  }
}
.line {
  border-bottom: 2px solid $primary;
  padding-bottom: 20px;
}
.hero {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.upper {
  text-transform: uppercase;
  padding-bottom: 0px;
}
.bl-azul {
  @media only screen and (min-width: 768px) {
    border-left: 2px solid $primary;
  }
}
