@import 'fonts.scss';
@import 'variables.scss';
@import 'general.scss';
@import 'menu.scss';
@import 'whatsapp.scss';
@import 'hero.scss';
@import 'nosotros.scss';
@import 'mision-y-vision.scss';
@import 'objeivos.scss';
@import 'servicios.scss';
@import 'transportistas.scss';
@import 'clientes.scss';
@import 'contacto.scss';
@import 'footer.scss';
