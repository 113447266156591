#top-nav{
    transition: ease-in-out .5s !important;
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1024;
    border-bottom: 1px solid #eaeaea;
    @media only screen and  (max-width: 767.98px){
        a{
            font-size: 20px;
        }
    }
} 