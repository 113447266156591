#descanso {
  padding: 125px 0px;
}
#footer {
  background-color: $negro;
  color: $blanco;
  padding: 25px 0px 15px 0px;
  h2 {
    font-size: 2.5rem;
    text-align: center;
    @media only screen and (min-width: 768px) {
      text-align: left;
      font-size: 2rem;
    }
  }
  ul {
    list-style: none;
    padding-left: 0px;
    text-align: center;
    @media only screen and (min-width: 768px) {
      text-align: left;
    }
    li {
      display: block;
      margin: 5px 0px;
      @media only screen and (min-width: 768px) {
        margin: 0px 5px;
        display: inline-block;
      }
      a {
        color: $blanco;
        display: block;
        font-size: 16px;
        @media only screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
  .social {
    list-style: none;
    padding-left: 0px;
    text-align: center;
    @media only screen and (min-width: 768px) {
      text-align: left;
    }
    li {
      display: inline-block;
      margin: 5px 10px;
      @media only screen and (min-width: 768px) {
        margin: 0px 5px;
        display: inline-block;
      }
      a {
        color: $blanco;
        display: block;
        font-size: 16px;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        border: 1px solid $blanco;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @media only screen and (min-width: 768px) {
          font-size: 12px;
          height: 35px;
          width: 35px;
        }
      }
    }
  }
}
