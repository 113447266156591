#hero {
  padding: 150px 0px 110px 0px;
  @media only screen and (min-width: 768px) and  (max-width: 1199.98px) {
    padding: 250px 0px;
  }  
  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100vh;
    padding: 0px;
  }
  h1 {
    color: $blanco;
    @media only screen and (min-width: 768px) {
      font-size: 70px;
      line-height: 70px;
    }
  }
  p {
    margin-top: 1rem;
    a {
      display: inline-block;
      padding: 12px 20px;
      background-color: $primary;
      color: $blanco;
      transition: ease-in-out 0.3s;
      &:hover {
        color: $blanco;
        background-color: $primaryHover;
        text-decoration: none;
      }
    }
  }
}
