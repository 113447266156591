#nosotros {
  padding: 80px 0px 0px 0px;
  @media only screen and (min-width: 1200px) {
    padding: 100px 0px 0px 0px;
  }
  h2 {
      font-size: 2.5rem;
    @media only screen and (min-width: 1200px){
        font-size: 60px;
    }
  }
}
