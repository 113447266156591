#contacto{
    padding: 50px 0px;
    background-color: #E6E6E6;
    @media only screen and (min-width: 768px) and (max-width: 1999px) {
      padding: 50px 0px;
    }
    @media only screen and (min-width: 1200px) {
      padding: 100px 0px;
    }
    ul{
        padding-left: 0px;
        list-style: none;
    }
}