@font-face {
    font-family: 'DIN 30640 Std Neuzeit Grotesk Light';
    font-style: normal;
    font-weight: normal;
    src:url('../fonts/DINNeuzeitGroteskStdLight.woff') format('woff');
    }
    @font-face {
    font-family: 'DIN 30640 Std Neuzeit Grotesk Bold Cond';
    font-style: normal;
    font-weight: normal;
    src:url('../fonts/DINNeuzeitGroteskStdBdCond.woff') format('woff');
    }