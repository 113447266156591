#pilares {
  padding: 50px 0px;
  background-position: center top;
  &::before {
    content: "";
    background-color: transparent;
  }
  @media only screen and (min-width: 768px) and (max-width: 1999px) {
    padding: 50px 0px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 120px 0px;
  }
  h2 {
    margin-bottom: 30px;
  }
  article {
    width: 100%;
    background-color: $blanco;
    margin: 20px 0px;
    padding: 30px;
    @media only screen and (min-width: 992px){
      height: 400px;
      box-sizing: inherit;
      padding: 50px 30px;
    }
    ul{
        padding-left: 0px;
        list-style: none;
    }
  }
}
