#whatsapp {
  background-color: #06d755;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1024;
  border-radius: 100%;
  a {
    color: $blanco;
    width: 70px;
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    &:hover {
      text-decoration: none;
    }
  }
}
