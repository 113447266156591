#mision-y-vision {
  .mision {
    background-color: #00cbf2;
    padding-top: 50px;
    padding-bottom: 100px;
    @media only screen and (min-width: 1200px){
        padding: 150px 0px;
    }
    h2 {
      color: $blanco;
    }
    p {
      color: $blanco;
    }
    ul {
      list-style: none;
      padding-left: 0px;
      margin: 0px;
      li {
        color: $blanco;
      }
    }
  }
  .vision {
    background-color: #5fecfa;
    padding-top: 50px;
    padding-bottom: 100px;
    @media only screen and (min-width: 1200px){
        padding: 150px 0px;
    }
    h2 {
      color: $primary;
    }
    p {
      color: $primary;
    }
    ul {
      list-style: none;
      padding-left: 0px;
      margin: 0px;
      li {
        color: $primary;
      }
    }
  }
}
