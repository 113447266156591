#servicios{
    padding: 50px 0px;
    @media only screen and (min-width: 768px) and (max-width: 1999px) {
      padding: 50px 0px;
    }
    @media only screen and (min-width: 1200px) {
      padding: 100px 0px; 
    }
    p{
        display: block;
        width: 100%;
    }
    ul{
        padding-left: 0px;
        list-style: none;
        width: 100%;
        li{
            letter-spacing: -.48px;
        }
    }
}